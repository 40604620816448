import { FC, FormEvent, useState } from 'react';
import { 
    Field,
    Slider,
    makeStyles
} from '@fluentui/react-components';
import BrixHeader from '../components/BrixHeader';
import BrixBreadcrumb from '../components/BrixBreadcrumb';
import BrixField from '../components/BrixField';
import BrixButton from '../components/BrixButton';
import { useInvoiceContext } from '../contexts/InvoiceContext';
import { calculateDisbursedAmount } from '../utils/CalcUtils';
import BrixProgressBar from '../components/BrixProgressBar';

const useStyles = makeStyles({
   root: {
       //color: 'blue'
   },
   sliderField: {
    textAlign: "left",
    paddingBottom: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  errorMessage:
  {
    color: "red",
    marginBottom: "50px"
  }
});

const ViewOfferPage: FC = () => {

    // usages
    const classes = useStyles();

    // states
    const [sliderValue, setSliderValue] = useState<number>(100);
    const [isLoading, setIsLoading] = useState<boolean>();

    // contexts
    const { invoice, getInvoice, updateInvoice } = useInvoiceContext();

    // Event handler for when the slider value is changed by dragging it
    const handleSliderChange = (_: any, data: { value: number }): void => 
    {
        // In case of decimal value, round it to nearest integer
        const newValue: number = Math.round(data.value);
        setSliderValue(newValue);
    };

    // Event handler for "Accept Offer" button
    const handleAcceptOffer = async (event: FormEvent<HTMLFormElement>) : Promise<void> => 
    {
        event.preventDefault();
        
        setIsLoading(true);

        if(invoice != null && invoice.invoiceAmount != null && sliderValue > 0 && sliderValue <= 100)
        {
            // set the sale status of the invoicesubmission to "Submitted" in the database via the api
            invoice.subStatus = "SUBACCEPTED";

            // calculate the sale amount based on the slider value and fee calculations
            //invoice.saleAmount = calculateDisbursedAmount(invoice.invoiceAmount, (sliderValue/100));
            invoice.saleAmount = invoice.invoiceAmount * (sliderValue/100);

            // update the in-context invoiceSubmission provided from the top level app provider
            await updateInvoice(invoice);

            await getInvoice(invoice.accessToken);
        }

        setIsLoading(false);
    }

    // Event handler for "Decline Offer" button
    const handleDeclineOffer = async (event: FormEvent<HTMLFormElement>) : Promise<void> =>
    {
        event.preventDefault();
        setIsLoading(true);
        if(event && invoice)
        { 
            invoice.saleAmount = 0;
            invoice.subStatus = "SUBDECLINED";
            await updateInvoice(invoice);
            await getInvoice(invoice.accessToken);
        }
        setIsLoading(false);
    }

    if(isLoading)
    {
        return (
            <BrixProgressBar message="Loading..." />
        );
    }
    
    return (
        <div className={classes.root} data-testid="view-offer-container">

            { (invoice != null && invoice != undefined) && <>
                {/* <BrixHeader title="You've Received an Offer!" subtitle={"You Can Have The Full " + `$${calculateDisbursedAmount(invoice?.invoiceAmount, (sliderValue/100)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` + " In Your Bank Account Tomorrow"} /> */}
                <BrixHeader title="You've Received an Offer!" subtitle={"You Can Have The Full Amount In Your Bank Account Tomorrow."} />
                
                <BrixBreadcrumb subStatus={invoice?.subStatus} />
                
                {/* { docusignLinkIsExpired && 
                    <div> 
                        Docusign link expired, please try again.
                    </div>
                } */}
                
                {/* <BrixField id="field-1" title="Invoice ID" value={invoice?.invoice_id.toString()} disabled={true}></BrixField> */}
                { invoice.gcName && <>
                    <BrixField id="field-2" title="General Contractor Name" value={invoice?.gcName.toString()} disabled={true} data-testid="gc-name"></BrixField>
                </>}
                { invoice.subcontractor_id && <>
                    <BrixField id="field-3" title="Subcontractor Name" value={invoice?.subContractorName} disabled={true} data-testid="subcontractor-name"></BrixField>
                </>}
                {/* <BrixField id="field-4" title="Project Name" value="Hotel California" disabled={true}></BrixField> */}
                <BrixField id="field-5" title="Invoice Number" value={invoice?.invoiceNumber} disabled={true} data-testid="invoice-number"></BrixField>
                <BrixField id="field-6" title="Invoice Amount" value={`$${invoice?.invoiceAmount.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} disabled={true} data-testid="invoice-amount"></BrixField>

                <Field
                    className={classes.sliderField}
                    size="large"
                    label={`Portion to Sell: ${sliderValue}%`}
                >
                    {/* <Label htmlFor={"partialAmount"}>Portion to Sell: {sliderValue}%</Label> */}
                    <Slider defaultValue={sliderValue} min={1} max={100} onChange={handleSliderChange} data-testid="portion-to-sell-slider"/>
                </Field>

                <BrixField id="field-6" title="Invoice Sale Amount" value={`$${(invoice?.invoiceAmount * (sliderValue/100)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} disabled={true} data-testid="invoice-sale-amount"></BrixField>

                { ((invoice?.invoiceAmount * (sliderValue/100)) < 10000) &&
                    <p className={classes.errorMessage}>Error: The mimimum sale amount is $10,000. Please adjust the slider to continue.</p>
                }

                {((invoice?.invoiceAmount * (sliderValue/100)) >= 10000) &&
                    <>
                        <BrixField id="field-7" title="Amount You Receive" value={`$${calculateDisbursedAmount(invoice?.invoiceAmount, (sliderValue/100)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} disabled={true} data-testid="amount-received"></BrixField>

                        <form onSubmit={handleAcceptOffer}>
                            {/* <BrixDialog id="offer-accept-dialog" buttonText="Accept" title="Accept" subtitle="Accept Offer?"></BrixDialog> */}
                            <BrixButton id="offer-accept-button" title="Accept" disabled={false} isSubmitButton={true} data-testid="accept-offer" />
                        </form>
                        <form onSubmit={handleDeclineOffer}>
                            <BrixButton id="offer-decline-button" title="Decline" disabled={false} isSubmitButton={true} data-testid="decline-offer" />
                        </form>
                    </>
                }
            </>}
        </div>
    );
}

export default ViewOfferPage;