import { createContext, useContext } from 'react';
import { ReceivableSale } from '../types/ReceivableSale'; 

interface ReceivableSaleContextProps {
  receivableSale: ReceivableSale | undefined,
  setReceivableSale: (receivableSale: ReceivableSale | undefined) => void;
  getReceivableSale: (accessToken: string) => Promise<void>;
  createReceivableSale: (invoice: ReceivableSale) => Promise<void>;
  updateReceivableSale: (invoice: ReceivableSale) => Promise<void>;
  deleteReceivableSale: (id: number) => Promise<void>;
}

const ReceivableSaleContext = createContext<ReceivableSaleContextProps>({
  receivableSale: undefined,
  setReceivableSale: () => {},
  getReceivableSale: async () => {},
  createReceivableSale: async () => {},
  updateReceivableSale: async () => {},
  deleteReceivableSale: async () => {},
});

export function useReceivableSale() {
  return useContext(ReceivableSaleContext);
}

export default ReceivableSaleContext;
