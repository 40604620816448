import { FC } from 'react';
import { 
    makeStyles,
    Button
} from '@fluentui/react-components';

const useStyles = makeStyles({
   button: {
    //height: "50px",
    //margin: "25px",
    // marginLeft: "5px",
    // marginTop: "25px",
    // marginBottom: "50px"
    display: "flex",
    margin: "5px",
    float: "right"
   }
});

interface BrixButtonProps {
    id: string;
    title: string;
    isSubmitButton?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

const BrixButton: FC<BrixButtonProps> = ({ title, isSubmitButton, disabled, onClick, ...props }) => {

   const classes = useStyles();

    if(isSubmitButton)
    {
        return (
            <Button type="submit" appearance="secondary" className={classes.button} disabled={disabled} onClick={onClick} {...props}>{title}</Button>
        );
    }
    else
    {
        return (
            <Button appearance="secondary" className={classes.button} disabled={disabled} onClick={onClick} {...props}>{title}</Button>
        );
    }
}

export default BrixButton;