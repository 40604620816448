import { FC } from 'react';
import { Image } from "@fluentui/react-components";
import { 
    makeStyles,
    FieldProps
} from '@fluentui/react-components';

const useStyles = makeStyles({
   root: {
    alignContent: "center",
    textAlign: "center",
    width: "100%"
   },
   img: {
    width: "50%",
    maxWidth: "500px",
    alignContent: "center",
    textAlign: "center",
    // marginLeft: "200px",
    // marginRight: "200px",
    margin: "25px"
   }
});

interface BrixImageProps extends FieldProps {
    url: string;
}

const BrixImage: FC<BrixImageProps> = ({ url }) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Image className={classes.img}
            block
            src={url}
            alt="Image placeholder"
            />
        </div>
    );
};

export default BrixImage;