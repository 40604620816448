import { FC } from 'react';
import { 
    makeStyles,
    Field,
    ProgressBar,
    FieldProps
} from '@fluentui/react-components';

const useStyles = makeStyles({
   root: {
    //alignContent: 'center',
    textAlign: 'center',
    padding: '20px'
   },
   bar: {
    //height: "5px"
   }
});

interface BrixProgressBarProps extends FieldProps {
    message: string;
}

const BrixProgressBar: FC<BrixProgressBarProps> = ({ message }) => {

   const classes = useStyles();

   return (
    <div className={classes.root}>
        <Field validationMessage={message} validationState="none">
            <ProgressBar thickness="large" className={classes.bar} shape="square" />
        </Field>
    </div>
   );
}

export default BrixProgressBar;