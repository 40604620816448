import { FC, useState, FormEvent, useEffect } from 'react';
import { Stack, Text } from '@fluentui/react';
import { makeStyles } from '@fluentui/react-components';
import { HttpService } from '../services/HttpService';
import BrixHeader from '../components/BrixHeader';
import BrixBreadcrumb from '../components/BrixBreadcrumb';
import BrixButton from '../components/BrixButton';
import BrixProgressBar from '../components/BrixProgressBar';
import { useInvoiceContext } from '../contexts/InvoiceContext';
import { BankAccount } from '../types/BankAccount';
import BrixField from '../components/BrixField';
import BankAccountService from '../services/BankAccountService';
import BrixText from '../components/BrixText';
import CameraCapture from '../components/CameraCapture';
import { dataURItoFile } from '../utils/fileUtils';
import { SubStatus } from '../types/SubStatus';

const useStyles = makeStyles({
   root: {
       paddingBottom: "50px",
   },
   mainStack: {
        marginTop: "20px"
    },
    errorDiv: {
     color: "red"
    }
});

const UploadCheckPage: FC = () => {
   const classes = useStyles();
   const [statusMessage, setStatusMessage] = useState<string>('');
   const [selectedImage, setSelectedImage] = useState<File | null>(null);
   const [requestSuccessful, setRequestSuccessful] = useState<boolean>(false);
   const [hasError, setHasError] = useState<boolean>(false);
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [errorMessage, setErrorMessage] = useState<string>("");
   const [bankName, setBankName] = useState<string>("");
   const [routingNumber, setRoutingNumber] = useState<string>("");
   const [accountNumber, setAccountNumber] = useState<string>("");
   const [checkImageObjectId, setCheckImageObjectId] = useState<string>("");
   const [showConfirmationForm, setShowConfirmationForm] = useState<boolean>(false);
   const [isCameraMode, setIsCameraMode] = useState(false);
   const [previewUrl, setPreviewUrl] = useState<string | null>(null);
   const [hasUserMadeChanges, setHasUserMadeChanges] = useState(false);

   const httpService = new HttpService();
   const bankAccountService = new BankAccountService();
   const { invoice, setBankAccountId, updateInvoice, getInvoice } = useInvoiceContext();

   const saveLienWaiver = async () : Promise<void> => {
        
        // now that we're back from the Proof page, and we don't yet have a lienwaiverid (meaning we did NOT self-notarize)
        // have the back end API save the Proof notarized lien waiver to the database
        if(invoice && !invoice.lienWaiverObjectId){

            setIsLoading(true);

            await httpService.get(`api/proof/SaveLienWaiverToDb/${invoice?.accessToken}`);
            invoice.lienWaiverObjectId = `${invoice.accessToken}-lien-waiver.pdf`;
            await updateInvoice(invoice);
            await getInvoice(invoice.accessToken);

            setIsLoading(false);
        }
   }

    useEffect(() => {
        saveLienWaiver();
    }, []);

   useEffect(() => {
     if (invoice?.subProfile_id || invoice?.subcontractor_id) {
        console.log('invoice', invoice.subProfile_id, invoice.subcontractor_id);
       setShowConfirmationForm(true);
       console.log('showConfirmationForm', showConfirmationForm);
       console.log('isCameraMode', isCameraMode);
     }
   }, [invoice, showConfirmationForm, isCameraMode]);

   const handleImageSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0] || null;
      setSelectedImage(file);
      if (file) {
        const url = URL.createObjectURL(file);
        setPreviewUrl(url);
      } else {
        setPreviewUrl(null);
      }
   };

   const handleImageUpload = async (event: FormEvent<HTMLFormElement>, image?: File): Promise<void> => {
        event.preventDefault();
        setIsLoading(true);
        setErrorMessage("");

        try {
            if (!selectedImage && !image) {
                throw new Error('Please select an image first.');
            }

            if (!invoice) {
                throw new Error('Invoice data is missing.');
            }
            const activeImage = image || selectedImage;
            if (!activeImage) {
                throw new Error('No image selected.');
            }
            const fileExtension = getFileExtension(activeImage.type);
            
            const formData = new FormData();
            formData.append('FormFile', activeImage);

            const checkAnalysisResponse = await httpService.post(
                `api/files/upload/checkimage/uploads/${invoice.accessToken}-check-image${fileExtension}`,
                formData
            ) as BankAccount;

            console.log('checkAnalysisResponse', checkAnalysisResponse);

            if (!checkAnalysisResponse) {
                throw new Error('Invalid response from server.');
            }
            updateStateWithResponse(checkAnalysisResponse);
            
            setShowConfirmationForm(true);
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
   };

   const getFileExtension = (mimeType: string): string => {
        switch(mimeType) {
            case "image/jpeg": return ".jpg";
            case "image/png": return ".png";
            case "application/pdf": return ".pdf";
            default: throw new Error("Unsupported file type. Please select a .pdf, .png, .jpeg, or .jpg file.");
        }
   };

   const updateStateWithResponse = (response: BankAccount) => {
    console.log("response", response);
        //setBankAccountId(response.bankAccount_id);
        setAccountNumber(response.accountNumber);
        setBankName(response.bankName);
        setRoutingNumber(response.routingNumber);
        setCheckImageObjectId(response.checkImageObjectId || '');
        setHasError(false);
        setStatusMessage('');
        setRequestSuccessful(true);      
        setHasUserMadeChanges(false);  // Reset the flag when we set initial values
   };

   const handleError = (error: unknown) => {
        console.error('Error uploading image:', error);
        setHasError(true);
        setErrorMessage(error instanceof Error ? error.message : "Error uploading image. Please try again or contact support.");
        setStatusMessage("");
        setRequestSuccessful(false);
   };

   const handleRetry = () => {
      setHasError(false);
      setStatusMessage('');
      setRequestSuccessful(false);
      setSelectedImage(null);
      setPreviewUrl(null);
   };

   const handleContinueToFinish = async (event: FormEvent<HTMLFormElement>) : Promise<void> => {
    event.preventDefault();

    if (invoice?.subProfile_id || invoice?.subcontractor_id) {
      invoice.subStatus = "SUBACCOUNTINFOPROVIDED";
      await updateInvoice(invoice);
      await getInvoice(invoice.accessToken);
    } else {
      try {
        console.log("Updating bank account details...");
        const updatedBankAccount: BankAccount = {
          bankName: bankName,
          accountNumber: accountNumber,
          routingNumber: routingNumber,
          checkImageObjectId: checkImageObjectId
        };
       const bankAccount = await bankAccountService.create(updatedBankAccount);
       setBankAccountId(bankAccount.bankAccount_id);
       console.log("bankAccount", bankAccount);
      } catch (error) {
        console.error('Error creating bank account:', error);
        setHasError(true);
        setErrorMessage('Failed to create bank account. Please try again.');
      }

      if(invoice) {
        invoice.subStatus = "SUBACCOUNTINFOPROVIDED" as SubStatus;
        await updateInvoice(invoice);
        await getInvoice(invoice.accessToken);
      }
    }
   };

    const handleBankNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBankName(event.target.value);
    }

    const handleAccountNumberChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAccountNumber(event.target.value);
    }

    const handleRoutingNumberChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRoutingNumber(event.target.value);
    }

   const handleImageCapture = (capturedImage: string) => {
      const file = dataURItoFile(capturedImage, 'check-image.jpg');
      setSelectedImage(file);
      setPreviewUrl(capturedImage);
      setIsCameraMode(false);
   }

   if(isLoading) {
        return (
            <BrixProgressBar message="Loading..." />
        );
    }

   return (
    <div className={classes.root} data-testid="upload-check-container">
        {errorMessage && 
            <div className={classes.errorDiv}>
                {errorMessage}
            </div>
        }

        {showConfirmationForm && (
            <>
                <BrixHeader 
                    title={invoice?.subProfile_id || invoice?.subcontractor_id ? "Existing Bank Account" : "Confirm Information"} 
                    subtitle={invoice?.subProfile_id || invoice?.subcontractor_id 
                        ? "We will wire the funds to the existing bank account we have on file." 
                        : "Please verify your Bank Name, Account Number, and Routing Number."} 
                />
                <BrixBreadcrumb subStatus={"SUBNOTARIZED"} />

                <form onSubmit={handleContinueToFinish}>
                    {invoice?.subProfile_id || invoice?.subcontractor_id ? (
                        <Stack>
                            <BrixText text="Please contact BRIX if your bank account information has changed." />
                        </Stack>
                    ) : (
                        <>
                            <BrixField id="bank-name-field" title="Bank Name" value={bankName} disabled={false} onChange={handleBankNameChanged} data-testid="bank-name" />
                            <BrixField id="routing-number-field" title="Routing Number" value={routingNumber} disabled={false} onChange={handleRoutingNumberChanged} data-testid="routing-number" />
                            <BrixField id="account-number-field" title="Account Number" value={accountNumber} disabled={false} onChange={handleAccountNumberChanged} data-testid="account-number" />
                            {hasUserMadeChanges && <BrixText text="You have made changes to the bank account details." />}
                        </>
                    )}

                    <BrixButton id="proceed-to-finish-button" title="Confirm" disabled={false} isSubmitButton={true} data-testid="confirm-bank-info" />
                </form>
            </>
        )}

        {!showConfirmationForm && (
            <Stack tokens={{ childrenGap: 20 }} horizontalAlign='center' className={classes.mainStack}> 
                <BrixHeader title="Notarization Complete!" subtitle="Please upload a check image to verify Account and Routing numbers." />
                <BrixBreadcrumb subStatus={"SUBNOTARIZED"} />

                {!requestSuccessful && (
                    <>
                        {!isCameraMode && !previewUrl ? (
                            <>
                                <input 
                                    type="file" 
                                    name="image" 
                                    accept="image/*"
                                    onChange={handleImageSelection}
                                    required
                                    data-testid="check-image-upload"
                                />
                                <Text variant="large">Or capture check image using camera:</Text>
                                <BrixButton 
                                    id="capture-start-button" 
                                    title="Start Camera" 
                                    disabled={false} 
                                    onClick={() => setIsCameraMode(true)} 
                                    data-testid="start-camera-button"
                                />
                            </>
                        ) : isCameraMode ? (
                            <CameraCapture onCapture={handleImageCapture} />
                        ) : (
                            <>
                                <img src={previewUrl!} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                                <form onSubmit={handleImageUpload}>
                                    <BrixButton id="upload-image-button" title="Upload Image" disabled={false} isSubmitButton={true} data-testid="upload-check-image" />
                                </form>
                                <BrixButton id="retry-button" title="Choose Different Image" disabled={false} onClick={handleRetry} data-testid="retry-button" />
                            </>
                        )}
                    </>
                )}

                <Text>{statusMessage}</Text>
                
                {hasError &&
                    <Stack>
                        <Text variant="large">Error: {statusMessage}</Text>
                        <form onSubmit={handleRetry}>
                            <BrixButton id="retry-button" title="Retry" disabled={false} isSubmitButton={true} data-testid="retry-upload" />
                        </form>
                    </Stack>
                }
            </Stack>
        )}
    </div>
   );
}

export default UploadCheckPage;