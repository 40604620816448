export const EntityTypeMap = {
  "Individual/sole proprietor or single-member LLC": "EntityTypeIndividual",
  "C Corporation": "EntityTypeCCorp",
  "S Corporation": "EntityTypeSCorp",
  "Partnership": "EntityTypePartnership",
  "Trust/estate": "EntityTypeTrustEstate",
  "Limited Liability Company": "EntityTypeLLC",
  "Other": "EntityTypeOther"
} as const;

export type EntityTypeMapKey = keyof typeof EntityTypeMap;
export type EntityTypeMapValue = typeof EntityTypeMap[EntityTypeMapKey];