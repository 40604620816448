import { FC, ChangeEvent } from 'react';
import { 
    makeStyles,
    FieldProps,
    Input,
    Field
} from '@fluentui/react-components';

const useStyles = makeStyles({
   root: {
    //alignContent: 'center',
    //textAlign: 'left',
    paddingBottom: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    // width: "100%",
    textAlign: "left"
   },
   bar: {
    //height: "5px"
   },
   input: {
    display: "flex",
    width: "100%"
    //marginBottom: "10px"
},
validationMessage: {
    //textAlign: 'right'
}
});

interface BrixFieldProps extends FieldProps {
    id: string;
    value?: string;
    title: string;
    disabled: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    'data-testid'?: string;
}

const BrixField: FC<BrixFieldProps> = ({ 
    id, 
    value, 
    title, 
    disabled, 
    validationState, 
    validationMessage, 
    onChange,
    'data-testid': dataTestId
}) => {

   const classes = useStyles();

   return (
    <div className={classes.root}>
        <Field
            id={id}
            size="large"
            className={classes.validationMessage}
            label={title}
            validationState={validationState}
            validationMessage={validationMessage}
        >
            <Input 
                id={id} 
                appearance="underline" 
                value={value} 
                disabled={disabled} 
                className={classes.input} 
                onChange={onChange}
                data-testid={dataTestId}
            />
        </Field>
    </div>
   );
}

export default BrixField;