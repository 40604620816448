// Usage:
// import BrixHeader from './components/BrixHeader'

import { FC } from 'react';
import { 
    FieldProps,
    makeStyles,
    Text
} from '@fluentui/react-components';

const useStyles = makeStyles({
   root: {
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
       justifyContent: "center",
       paddingBottom: "20px"
   }
});

interface BrixTextProps extends FieldProps {
    text: string;
}

const BrixText: FC<BrixTextProps> = ({ text }) => {

   const classes = useStyles();

   return (
    <div className={classes.root}>
        <div>
            <Text>{text}</Text>
        </div>
    </div>
   );
}

export default BrixText;