import { useState } from 'react';
import InvoiceContext from '../contexts/InvoiceContext';
import { Invoice } from '../types/Invoice'; 
import InvoiceService from '../services/InvoiceService';

const invoiceService = new InvoiceService();

export function InvoiceProvider({ children }: { children: React.ReactNode }) {
  const [invoice, setInvoice] = useState<Invoice | undefined>(undefined);
  const [bankAccountId, setBankAccountId] = useState<string | undefined>(undefined);

  const getInvoice = async (id: string) => {
    console.log("getInvoice in InvoiceProvider triggered.");
    const fetchedInvoice = await invoiceService.get(id);
    setInvoice(fetchedInvoice);
    // If bankAccountId is not in the fetched invoice, we keep the existing one
    if (!fetchedInvoice.bankAccountId && bankAccountId) {
      setBankAccountId(bankAccountId);
    } else if (fetchedInvoice.bankAccountId) {
      setBankAccountId(fetchedInvoice.bankAccountId);
    }
  }

  async function createInvoice(invoice: Invoice) {
    await invoiceService.create(invoice);
    setInvoice(invoice);
  }

  async function updateInvoice(invoice: Invoice) {
    await invoiceService.update(invoice);
    setInvoice(invoice);
    // Update bankAccountId if it's present in the updated invoice
    if (invoice.bankAccountId) {
      setBankAccountId(invoice.bankAccountId);
    }
  }

  async function deleteInvoice(id: number) {
    await invoiceService.delete(id);
    setInvoice(undefined);
    setBankAccountId(undefined);
  }
  
  return (
    <InvoiceContext.Provider value={{
      invoice,
      bankAccountId,
      setInvoice,
      setBankAccountId,
      getInvoice,
      createInvoice,
      updateInvoice,
      deleteInvoice
    }}>
      {children}
    </InvoiceContext.Provider>
  );
}
