// Function to calculate Annual Percentage Rate (APR)
// The parameters are the portion of the invoice we will sell, the amount we will receive, and the term of the invoice.
export function calculateAPR(portion: number, amountReceived: number, daysInTerm: number): number {
    // Calculate the interest charges by subtracting the amount received from the portion of the invoice.
    const interestCharges: number = portion - amountReceived;
    // Set fee based on if the portion is above 250000 or not
    const fee: number = portion > 250000 ? 250 : 100;
    
    // Calculate APR:
    // - Take the sum of interest charges and the fee, and divide by the amount received.
    // - Normalize for term length by dividing by number of days and multiplying by the number of days in a year.
    // - Convert to percentage by multiplying by 100.
    return (((interestCharges + fee) / amountReceived) / daysInTerm) * 365 * 100;
}

// Function to calculate the number of days between two dates, including both start and end dates
export function daysBetweenDates(date1: Date, date2: Date): number {
    // Create new Date objects with only the date components to ignore time
    const startDate = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const endDate = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

    // Calculate the difference in milliseconds
    const diffInMs: number = Math.abs(endDate.getTime() - startDate.getTime());
    
    // Convert milliseconds to days and add 1 to include both start and end dates
    const diffInDays: number = Math.floor(diffInMs / (1000 * 60 * 60 * 24)) + 1;
    
    return diffInDays;
}

// Function to calculate the amount of the invoice we will sell
export function calculateSaleAmount(invoiceAmount: number, percentageSelling: number){
    // Return the amount to be sold by multiplying the invoice amount by the percentage we will sell
    return invoiceAmount * percentageSelling;
}

// Function to estimate the fee that will be charged based on the amount of the invoice we will sell
export function calculateFee(amount: number){
    // Calculate the fee depending on the amount being sold. 
    // If more than 250000, then fee is 6% + 250.
    // If between 100000 and 250000, then fee is 7% + 100.
    // If less than 100000, then fee is 8% + 100.
    // Return the calculated fee.
    if (amount > 250000){
        return (amount * 0.06) + 250;
    }else if (amount > 100000){
        return (amount * 0.07) + 100;
    }else{
        return (amount * 0.08) + 100;
    }
}

// Function to calculate amount disbursed after selling a portion of the invoice and deducting the fee
export function calculateDisbursedAmount(invoiceAmount: number, percentageSelling: number){
    // Determine the portion of the invoice we will sell
    const portionOfInvoiceSelling = calculateSaleAmount(invoiceAmount, percentageSelling);
    if (portionOfInvoiceSelling === 0) return 0;
    // Determine the fee for the portion of the invoice 
    const fees = calculateFee(portionOfInvoiceSelling);
    // Subtract the fee from the portion of the invoice to get the total disbursed amount
    const disbursedAmount = portionOfInvoiceSelling - fees;
    
    // Return the disbursed amount
    return disbursedAmount;
}