// Usage:
// import BrixTemplate from './components/BrixTemplate'

import { FC, FormEvent, useState } from 'react';
import { 
    FieldProps,
    makeStyles
} from '@fluentui/react-components';
import BrixHeader from '../components/BrixHeader';
import BrixBreadcrumb from '../components/BrixBreadcrumb';
import BrixButton from '../components/BrixButton';
import { useInvoiceContext } from '../contexts/InvoiceContext';
import BrixProgressBar from '../components/BrixProgressBar';

const useStyles = makeStyles({
   root: {
       //color: 'blue'
   }
});

interface BrixTemplateProps extends FieldProps {
    id?: string;
}

const BrixTemplate: FC<BrixTemplateProps> = ({ id }) => {

    console.log(id);

    const classes = useStyles();

    const { invoice, getInvoice, updateInvoice } = useInvoiceContext();
    const [isLoading, setIsLoading] = useState<boolean>();

    // Event handler for "Reset Offer" button
    const handleResetOffer = async (event: FormEvent<HTMLFormElement>) : Promise<void> =>
    {
        event.preventDefault();
        setIsLoading(true);
        if(event && invoice)
        { 
            invoice.saleAmount = 0;
            invoice.subStatus = "SUBCREATED";
            await updateInvoice(invoice);
            await getInvoice(invoice.accessToken);
        }
        setIsLoading(false);
    }

    if(isLoading)
    {
        return (
            <BrixProgressBar message="Loading..." />
        );
    }

    return (
    <div className={classes.root} data-testid="decline-offer-container">
        <BrixHeader title="Offer Declined" subtitle="You can use the button below to undo your decline up until the expiration date." />
        <BrixBreadcrumb subStatus={"SUBFINISHED"} />
        <form onSubmit={handleResetOffer}>
            <BrixButton id="reset-offer-field" title="Reset Offer" isSubmitButton={true} disabled={false} data-testid="reset-offer" />
        </form>
    </div>
    );
}

export default BrixTemplate;