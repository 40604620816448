import React, { FC, FormEvent, useEffect, useState } from 'react';
import { makeStyles } from '@fluentui/react-components';
import BrixHeader from '../components/BrixHeader';
import BrixDropdown from '../components/BrixDropdown';
import BrixField from '../components/BrixField';
import BrixBreadcrumb from '../components/BrixBreadcrumb';
import BrixButton from '../components/BrixButton';
import BrixProgressBar from '../components/BrixProgressBar';
import { useInvoiceContext } from '../contexts/InvoiceContext';
import SubProfileService from '../services/SubProfileService';
import SubQueueService from '../services/SubQueueService';
import SubService from '../services/SubService';
import { StateOptions } from '../enums';
import { EntityOptions, EntityOption } from '../enums/EntityOptions';
import { EntityTypeMap, EntityTypeMapValue } from '../enums/EntityTypeMap';
import { SubQueue } from '../types/SubQueue';
import { Invoice } from '../types/Invoice';
import BrixText from '../components/BrixText';

const useStyles = makeStyles({
  root: {
    paddingBottom: '60px',
  },
  errorDiv: {
    marginTop: '10px',
    color: '#cb0043'
  },
});

interface FormData extends Omit<SubQueue, 'subQueue_id' | 'subcontractor_id'> {
  entityType: EntityTypeMapValue;
}

const CompanyInfoPage: FC = () => {
  const classes = useStyles();
  const { invoice, getInvoice, updateInvoice } = useInvoiceContext();
  const [formData, setFormData] = useState<FormData>({
    gC_id: 0,
    subcontractorName: '',
    contactName: '',
    emailAddress: '',
    cellPhone: '',
    ein: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    entityType: '' as EntityTypeMapValue,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [einError, setEinError] = useState('');
  const [isSubProfile, setIsSubProfile] = useState(false);
  const [isExistingSubcontractor, setIsExistingSubcontractor] = useState(false);

  const subProfileService = new SubProfileService();
  const subQueueService = new SubQueueService();
  const subService = new SubService();

  useEffect(() => {
    const fetchData = async () => {
      if (invoice) {
        let data;
        if (invoice.subProfile_id) {
          setIsSubProfile(true);
          data = await subProfileService.get(invoice.subProfile_id);
        } else if (invoice.subQueue_id) {
          data = await subQueueService.get(invoice.subQueue_id);
        }
        if (data) {
          setFormData({
            gC_id: data.gC_id,
            subcontractorName: data.subcontractorName || '',
            contactName: data.contactName || '',
            emailAddress: data.emailAddress || '',
            cellPhone: data.cellPhone || '',
            ein: data.ein || '',
            address1: data.address1 || '',
            address2: data.address2 || '',
            city: data.city || '',
            state: data.state || '',
            zipcode: data.zipcode || '',
            entityType: data.entityType as EntityTypeMapValue || '',
          });
          if (data.ein && validateEIN(data.ein)) {
            setIsLoading(true);
            const existingSubcontractor = await subService.getByEIN(data.ein);
            if (existingSubcontractor) {
              setFormData(prev => ({
                ...prev,
                subcontractorName: existingSubcontractor.subContractorName || '',
                ein: data.ein,
                address1: existingSubcontractor.address1 || '',
                address2: existingSubcontractor.address2 || '',
                city: existingSubcontractor.city || '',
                state: existingSubcontractor.state || '',
                zipcode: existingSubcontractor.zipCode || '',
                entityType: existingSubcontractor.entityType as EntityTypeMapValue || '',
              }));
              console.log('existingSubcontractor', existingSubcontractor);
              invoice.subcontractor_id = existingSubcontractor.subContractor_id;
              setIsExistingSubcontractor(true);
            } else {
              setIsExistingSubcontractor(false);
            }
          }
        }
        
      }
      setIsLoading(false);
    };
    fetchData();
  }, [invoice]);

  const validateEIN = (ein: string): boolean => {
    const einPattern = /^\d{2}-\d{7}$/;
    return einPattern.test(ein);
  };

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    
    if (isSubProfile || (isExistingSubcontractor && id !== 'company-ein-field')) return;

    const fieldMap: { [key: string]: keyof FormData } = {
      'company-name-field': 'subcontractorName',
      'company-ein-field': 'ein',
      'company-address1-field': 'address1',
      'company-address2-field': 'address2',
      'company-city-field': 'city',
      'company-zipcode-field': 'zipcode',
    };

    const field = fieldMap[id];
    if (field) {
      setFormData(prev => ({ ...prev, [field]: value }));
    }

    if (id === 'company-ein-field') {
      const isValidEIN = validateEIN(value);
      setEinError(isValidEIN ? '' : 'EIN must be in the format 12-3456789');

      if (isValidEIN) {
        const existingSubcontractor = await subService.getByEIN(value);
        if (existingSubcontractor) {
          setFormData(prev => ({
            ...prev,
            subcontractorName: existingSubcontractor.subContractorName || '',
            ein: value,
            address1: existingSubcontractor.address1 || '',
            address2: existingSubcontractor.address2 || '',
            city: existingSubcontractor.city || '',
            state: existingSubcontractor.state || '',
            zipcode: existingSubcontractor.zipCode || '',
            entityType: existingSubcontractor.entityType as EntityTypeMapValue || '',
          }));
          setIsExistingSubcontractor(true);
          if (invoice) {
            invoice.subcontractor_id = existingSubcontractor.subContractor_id;
          }
        } else {
          setIsExistingSubcontractor(false);
          if (invoice) {
            invoice.subcontractor_id = null;
          }
        }
      } else {
        // Only clear form if there was previously an existing subcontractor
        if (isExistingSubcontractor) {
          setFormData(prev => ({
            ...prev,
            subcontractorName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipcode: '',
            entityType: '' as EntityTypeMapValue,
          }));
        }
        setIsExistingSubcontractor(false);
        if (invoice) {
          invoice.subcontractor_id = null;
        }
      }
    }
  };

  const handleDropdownChange = (id: string) => (value: string) => {
    if (isSubProfile || (isExistingSubcontractor && id !== 'state-field')) return;
    
    const fieldMap: { [key: string]: keyof FormData } = {
      'state-field': 'state',
      'entity-type-field': 'entityType',
    };

    const field = fieldMap[id];
    if (field) {
      if (field === 'entityType') {
        // Convert EntityOptions display value to EntityTypeMap server value
        const serverValue = EntityTypeMap[value as EntityOption];
        setFormData(prev => ({ ...prev, [field]: serverValue }));
      } else {
        setFormData(prev => ({ ...prev, [field]: value }));
      }
    }
  };

  // Helper function to get display value from server value
  const getEntityTypeDisplayValue = (serverValue: EntityTypeMapValue): EntityOption => {
    const entry = Object.entries(EntityTypeMap).find(([_, value]) => value === serverValue);
    return entry ? entry[0] as EntityOption : EntityOptions[0];
  };

  const onCompanyInfoSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const dataToSave = {
        ...formData,
        // No need to convert entityType here, as it's already in EntityTypeMap format
      };
console.log('dataToSave', dataToSave);
      if (isSubProfile && invoice?.subProfile_id) {
        console.log("Confirming subProfile data:", dataToSave);
      } else if (invoice?.subQueue_id) {
        const updateData: SubQueue = {
          gC_id: dataToSave.gC_id,
          contactName: dataToSave.contactName,
          emailAddress: dataToSave.emailAddress,
          subQueue_id: invoice.subQueue_id,
          subcontractorName: dataToSave.subcontractorName,
          ein: dataToSave.ein,
          address1: dataToSave.address1,
          address2: dataToSave.address2,
          city: dataToSave.city,
          state: dataToSave.state,
          zipcode: dataToSave.zipcode,
          entityType: dataToSave.entityType,
        } as SubQueue;
        await subQueueService.update(updateData);
      }

      if (invoice) {
        console.log('invoice account', invoice.subcontractor_id);
        const updatedInvoice: Invoice = {
          ...invoice,
          subStatus: "SUBCOMPANYINFOPROVIDED"
        };
        await updateInvoice(updatedInvoice);
        await getInvoice(invoice.accessToken);
      }
    } catch (error) {
      console.error("Error processing company information:", error);
    }

    setIsLoading(false);
  };

  if (isLoading) {
    return <BrixProgressBar message="Loading..." />;
  }

  const isSubmitDisabled = 
    formData.state === StateOptions.CA ||
    !formData.state ||
    !formData.zipcode ||
    !formData.subcontractorName ||
    !formData.address1 ||
    !formData.city ||
    !formData.ein ||
    formData.zipcode.length !== 5 ||
    Boolean(einError) ||
    !formData.entityType;

  return (
    <div className={classes.root} data-testid="company-info-container">
      <BrixHeader title="Offer Accepted!" subtitle="Please confirm your company details below." subtitle2={isSubProfile || isExistingSubcontractor ? "If any information is incorrect please contact BRIX." : ""} />
      {(isSubProfile || isExistingSubcontractor) && (
        <BrixText text={isExistingSubcontractor ? "Is this you?" : ""} />
      )}
      <BrixBreadcrumb subStatus="SUBACCEPTED" />
      <form onSubmit={onCompanyInfoSubmit}>
        <BrixField id="company-name-field" title="Company Name *" disabled={isSubProfile || isExistingSubcontractor} value={formData.subcontractorName} onChange={handleInputChange} data-testid="company-name"/>
        <BrixField id="company-ein-field" title="Company EIN *" disabled={isSubProfile} value={formData.ein} onChange={handleInputChange} data-testid="company-ein"/>
        {einError && <div className={classes.errorDiv}>{einError}</div>}
        <BrixField id="company-address1-field" title="Address 1 *" disabled={isSubProfile || isExistingSubcontractor} value={formData.address1} onChange={handleInputChange} data-testid="company-address1"/>
        <BrixField id="company-address2-field" title="Address 2" disabled={isSubProfile || isExistingSubcontractor} value={formData.address2} onChange={handleInputChange} data-testid="company-address2"/>
        <BrixField id="company-city-field" title="City *" disabled={isSubProfile || isExistingSubcontractor} value={formData.city} onChange={handleInputChange} data-testid="company-city"/>
        <BrixDropdown 
          id="state-field" 
          label="State *" 
          defaultValue={formData.state} 
          options={Object.values(StateOptions)} 
          onOptionChange={handleDropdownChange('state-field')} 
          disabled={isSubProfile || isExistingSubcontractor}
          data-testid="company-state"
        />
        {formData.state === StateOptions.CA && (
          <div className={classes.errorDiv}>
            Sorry, our service is not available in California.
          </div>
        )}
        <BrixField id="company-zipcode-field" title="Zip Code *" disabled={isSubProfile || isExistingSubcontractor} value={formData.zipcode} onChange={handleInputChange} data-testid="company-zipcode"/>
        <BrixDropdown 
          id="entity-type-field" 
          label="Entity Type *" 
          defaultValue={formData.entityType ? getEntityTypeDisplayValue(formData.entityType) : ''} 
          options={[...EntityOptions]} 
          onOptionChange={handleDropdownChange('entity-type-field')} 
          disabled={isSubProfile || isExistingSubcontractor}
          data-testid="company-entity-type"
        />
        <BrixButton 
          title="Confirm" 
          id="submit-button" 
          isSubmitButton={true} 
          disabled={isSubmitDisabled} 
          data-testid="confirm-company-info"
        />
      </form>
    </div>
  );
};

export default CompanyInfoPage;