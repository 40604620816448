import React, { useRef, useState } from 'react';
import { Camera, CameraType } from 'react-camera-pro';
import { makeStyles, shorthands } from '@fluentui/react-components';
import cameraImage from '../assets/compact-camera.png';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: '600px',
    aspectRatio: '16 / 9',
    margin: '0 auto',
    overflow: 'hidden',
  },
  camera: {
    width: '100%',
    height: '100%',
  },
  control: {
    position: 'absolute',
    display: 'flex',
    right: 0,
    top: 0,
    width: '20%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.8)',
    zIndex: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    ...shorthands.padding('20px'),
    boxSizing: 'border-box',
    flexDirection: 'column-reverse',
    '@media (max-aspect-ratio: 1/1)': {
      flexDirection: 'row',
      bottom: 0,
      top: 'auto',
      width: '100%',
      height: '20%',
    },
    '@media (max-width: 600px)': {
      flexDirection: 'row',
      bottom: 0,
      top: 'auto',
      width: '100%',
      height: '20%',
      justifyContent: 'center',
    },
    '@media (max-width: 400px)': {
      ...shorthands.padding('10px'),
    },
  },
  button: {
    ...shorthands.outline('none'),
    color: 'white',
    opacity: 1,
    background: 'transparent',
    backgroundColor: 'transparent',
    backgroundPositionX: '0%',
    backgroundPositionY: '0%',
    backgroundImage: 'none',
    ...shorthands.padding('0'),
    textShadow: '0px 0px 4px black',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    pointerEvents: 'auto',
    cursor: 'pointer',
    zIndex: 2,
    filter: 'invert(100%)',
    ...shorthands.border('none'),
    ':hover': {
      opacity: 0.7,
    },
  },
  takePhotoButton: {
    background: `url('${cameraImage}')`,
    backgroundColor: '#002d62',
    backgroundPosition: 'center',
    backgroundSize: '50px',
    backgroundRepeat: 'no-repeat',
    width: '80px',
    height: '80px',
    ...shorthands.border('solid', '4px', 'white'),
    borderRadius: '50%',
    ':hover': {
      backgroundColor: 'darkred',
    },
  },
  torchButton: {
    background: "url('https://img.icons8.com/ios/50/000000/light.png')",
    backgroundPosition: 'center',
    backgroundSize: '50px',
    backgroundRepeat: 'no-repeat',
    width: '80px',
    height: '80px',
    ...shorthands.border('solid', '4px', 'black'),
    borderRadius: '50%',
    '&.toggled': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  changeFacingCameraButton: {
    background: "url(https://img.icons8.com/ios/50/000000/switch-camera.png)",
    backgroundPosition: 'center',
    backgroundSize: '40px',
    backgroundRepeat: 'no-repeat',
    width: '40px',
    height: '40px',
    ...shorthands.padding('40px'),
    ':disabled': {
      opacity: 0,
      cursor: 'default',
      ...shorthands.padding('60px'),
    },
    '@media (max-width: 400px)': {
      ...shorthands.padding('40px', '5px'),
      ':disabled': {
        ...shorthands.padding('40px', '25px'),
      },
    },
  },
  deviceSelect: {
    marginBottom: '10px',
    padding: '5px',
    backgroundColor: 'white',
    border: '1px solid black',
    borderRadius: '5px',
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
});

interface CameraCaptureProps {
  onCapture: (image: string) => void;
}

const CameraCapture: React.FC<CameraCaptureProps> = ({ onCapture }) => {
  const styles = useStyles();
  const camera = useRef<CameraType>(null);
  const [isCameraReady, setIsCameraReady] = useState(false);

  const handleCapture = () => {
    if (camera.current && isCameraReady) {
      const photo = camera.current.takePhoto();
      const photoString = typeof photo === 'string' ? photo : photo.toString();
      onCapture(photoString);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.camera}>
        <Camera
          ref={camera}
          aspectRatio="cover"
          facingMode="environment"
          errorMessages={{
            noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
            permissionDenied: 'Permission denied. Please refresh and give camera permission.',
            switchCamera:
              'It is not possible to switch camera to different one because there is only one video device accessible.',
            canvas: 'Canvas is not supported.',
          }}
          videoReadyCallback={() => {
            console.log('Video feed ready.');
            setIsCameraReady(true);
          }}
        />
      </div>
      <div className={styles.control}>
        <button 
          className={styles.takePhotoButton}
          onClick={handleCapture}
          disabled={!isCameraReady}
        />
      </div>
    </div>
  );
};

export default CameraCapture;