import "./instrument";
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import { FluentProvider, webLightTheme } from '@fluentui/react-components'
import { ThemeProvider } from '@fluentui/react'
import { InvoiceProvider } from './providers/InvoiceProvider'
import { ReceivableSaleProvider } from './providers/ReceivableSaleProvider'

// Using ReactDOM.createRoot to prepare for the upcoming React concurrent mode 
const rootElement = document.getElementById('root')!
const root = ReactDOM.createRoot(rootElement)

root.render(
    <FluentProvider theme={webLightTheme}>
      <ThemeProvider>
        <InvoiceProvider>
          <ReceivableSaleProvider>
              <App />
          </ReceivableSaleProvider>
        </InvoiceProvider>
      </ThemeProvider>
    </FluentProvider>
)