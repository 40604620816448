import { SubQueue } from '../types/SubQueue';
import { HttpService } from './HttpService';

const httpService = new HttpService();

export class SubQueueService {
  
  async get(id: number): Promise<SubQueue> {
      const subqueue = await httpService.get(`api/subqueues/${id}`) as SubQueue;
      return subqueue;
  }

  async create(subqueue: SubQueue): Promise<void> {
      await httpService.post('api/subqueues', subqueue);
  }

  async update(subqueue: SubQueue): Promise<void> {
      await httpService.put(`api/subqueues/${subqueue.subQueue_id}`, subqueue);
  }

  async delete(id: number): Promise<void> {
      await httpService.delete(`api/subqueues/${id}`);
  }
}

export default SubQueueService;