export const EntityOptions = [
  "Individual/sole proprietor or single-member LLC",
  "C Corporation",
  "S Corporation",
  "Partnership",
  "Trust/estate",
  "Limited Liability Company",
  "Other",
] as const;

export type EntityOption = typeof EntityOptions[number];