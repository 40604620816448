import { FC } from 'react';
import { 
    FieldProps,
    makeStyles,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbButton,
    BreadcrumbDivider,
    mergeClasses
} from '@fluentui/react-components';
import { 
    HandshakeRegular,
    DocumentSignatureRegular,
    VideoPersonStarRegular,
    CheckRegular,
    CheckmarkStarburstRegular,
    SearchInfoRegular
   } from "@fluentui/react-icons";
import { SubStatus } from '../types/SubStatus';

const useStyles = makeStyles({
   root: {
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
       justifyContent: "center",
       paddingBottom: "20px"
   },
   breadcrumb: {
     '@media (max-width: 768px)': {
       '& .breadcrumbText': {
         display: 'none',
       },
       '& .breadcrumbItem': {
         marginRight: '4px',
       },
       '& .ms-Breadcrumb-itemLink': {
         padding: '4px',
       },
       '& .ms-Breadcrumb-chevron': {
         display: 'none',
       },
       '& .ms-Breadcrumb-list': {
         flexWrap: 'nowrap',
       },
     },
   },
   activeBreadcrumb: {
     '@media (max-width: 768px)': {
       '& .breadcrumbText': {
         display: 'inline',
       },
     },
   },
   hiddenDivider: {
     '@media (max-width: 768px)': {
       display: 'none',
     },
   },
});

interface BrixBreadcrumbProps extends FieldProps {
    subStatus: SubStatus | undefined;
}

const BrixBreadcrumb: FC<BrixBreadcrumbProps> = ({ subStatus }) => {
   const classes = useStyles();

   const getBreadcrumbItemClass = (status: SubStatus) => {
     return mergeClasses(
       'breadcrumbItem',
       subStatus === status ? classes.activeBreadcrumb : ''
     );
   };

   return (
    <div className={classes.root}>
      {subStatus && (
        <Breadcrumb aria-label="Brix Breadcrumb" className={classes.breadcrumb}>
          <BreadcrumbItem className={getBreadcrumbItemClass("SUBCREATED")}>
            <BreadcrumbButton href={"#"} disabled={(subStatus !== "SUBCREATED")} current={(subStatus == "SUBCREATED")} icon={<HandshakeRegular />}>
              <span className="breadcrumbText">Accept Offer</span>
            </BreadcrumbButton>
          </BreadcrumbItem>
          <BreadcrumbDivider className={classes.hiddenDivider} />
          <BreadcrumbItem className={getBreadcrumbItemClass("SUBACCEPTED")}>
            <BreadcrumbButton href={"#"} disabled={(subStatus !== "SUBACCEPTED")} current={(subStatus == "SUBACCEPTED")} icon={<SearchInfoRegular />}>
              <span className="breadcrumbText">Company Info</span>
            </BreadcrumbButton>
          </BreadcrumbItem>
          <BreadcrumbDivider className={classes.hiddenDivider} />
          <BreadcrumbItem className={getBreadcrumbItemClass("SUBCOMPANYINFOPROVIDED")}>
            <BreadcrumbButton href={"#"} disabled={(subStatus !== "SUBCOMPANYINFOPROVIDED")} current={(subStatus == "SUBCOMPANYINFOPROVIDED")} icon={<DocumentSignatureRegular />}>
              <span className="breadcrumbText">Sign Documents</span>
            </BreadcrumbButton>
          </BreadcrumbItem>
          <BreadcrumbDivider className={classes.hiddenDivider} />
          <BreadcrumbItem className={getBreadcrumbItemClass("SUBDOCUSIGNED")}>
            <BreadcrumbButton href={"#"} disabled={(subStatus !== "SUBDOCUSIGNED")} current={(subStatus == "SUBDOCUSIGNED")} icon={<VideoPersonStarRegular />}>
              <span className="breadcrumbText">Get Notarized</span>
            </BreadcrumbButton>
          </BreadcrumbItem>
          <BreadcrumbDivider className={classes.hiddenDivider} />
          <BreadcrumbItem className={getBreadcrumbItemClass("SUBNOTARIZED")}>
            <BreadcrumbButton href={"#"} disabled={(subStatus !== "SUBNOTARIZED")} current={(subStatus == "SUBNOTARIZED")} icon={<CheckRegular />}>
              <span className="breadcrumbText">Upload Check</span>
            </BreadcrumbButton>
          </BreadcrumbItem>
          <BreadcrumbDivider className={classes.hiddenDivider} />
          <BreadcrumbItem className={getBreadcrumbItemClass("SUBFINISHED")}>
            <BreadcrumbButton 
              href={"#"} 
              disabled={(subStatus !== "SUBEXPIRED" && subStatus !== "SUBDECLINED" && subStatus !== "SUBACCOUNTINFOPROVIDED" && subStatus !== "SUBFINISHED")} 
              current={(subStatus == "SUBEXPIRED" || subStatus == "SUBDECLINED" || subStatus == "SUBACCOUNTINFOPROVIDED" || subStatus == "SUBFINISHED")} 
              icon={<CheckmarkStarburstRegular />}
            >
              <span className="breadcrumbText">Finish</span>
            </BreadcrumbButton>
          </BreadcrumbItem>
        </Breadcrumb>
      )}
    </div>
   );
}

export default BrixBreadcrumb;