import { HttpService } from './HttpService';

const httpService = new HttpService();

export class DocuSignService {
  
  async getProofUrl(docusignEnvelopeid: string, accessToken: string): Promise<string> {
      const proofUrl = await httpService.get(`api/docusign/ProofUrl/${docusignEnvelopeid}/${accessToken}`) as string;
      return proofUrl;
  }

  async downloadDocuments(invoiceId: string): Promise<void> {
    await httpService.get(`api/docusign/DownloadSignedDocument/${invoiceId}`);
  }
}

export default DocuSignService;