import { useState } from 'react';
import ReceivableSaleContext from '../contexts/ReceivableSaleContext';
import { ReceivableSale } from '../types/ReceivableSale'; 
import ReceivableSaleService from '../services/ReceivableSaleService';

const receivableSaleService = new ReceivableSaleService();

export function ReceivableSaleProvider({ children }: {children: React.ReactNode}) {

  const [receivableSale, setReceivableSale] = useState<ReceivableSale>();

  async function getReceivableSale(accessToken: string) {
    const invoice = await receivableSaleService.get(accessToken);
    setReceivableSale(invoice);
  }

  async function createReceivableSale(invoice: ReceivableSale) {
    await receivableSaleService.create(invoice);
    setReceivableSale(invoice);
  }

  async function updateReceivableSale(invoice: ReceivableSale) {
    await receivableSaleService.update(invoice);
    setReceivableSale(invoice);
  }

  async function deleteReceivableSale(id: number) {
    await receivableSaleService.delete(id);
    setReceivableSale(undefined);
  }
  
  return (
    <ReceivableSaleContext.Provider value={{ receivableSale, setReceivableSale, getReceivableSale, createReceivableSale, updateReceivableSale, deleteReceivableSale }}>
      {children}
    </ReceivableSaleContext.Provider>
  );
}