export function isValidGuid(guid?: string) : boolean 
{

    if(guid == null || guid.length != 36)
    {
        return false;
    }

    // if we reach here, the offer code has passed all validation
    return true;

}