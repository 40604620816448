import { FC, FormEvent, useState } from 'react';
import {
    makeStyles
} from '@fluentui/react-components';
import { HttpService } from '../services/HttpService';
import DocuSignService from "../services/DocuSignService";
import BrixHeader from '../components/BrixHeader';
import BrixBreadcrumb from '../components/BrixBreadcrumb';
import BrixButton from '../components/BrixButton';
import { useInvoiceContext } from '../contexts/InvoiceContext';
import BrixProgressBar from '../components/BrixProgressBar';
import BrixText from '../components/BrixText';

const useStyles = makeStyles({
   root: {
       //color: 'blue'
   },
   fileUploadDiv: {
    width: "100%",
    height: "50px"
   },
   fileUploadInput: {
    paddingLeft: "100px"
   },
   errorDiv:
   {
    color: "red"
   }
});

const NotarizeDocumentsPage: FC = () => {

    const classes = useStyles();

    const httpService = new HttpService();

    // const { getReceivableSale, createReceivableSale, updateReceivableSale, deleteReceivableSale } = useReceivableSale();
    const { invoice, getInvoice, updateInvoice } = useInvoiceContext();
    
    const [isLoading, setIsLoading] = useState<boolean>();
    const [isByonSelected, setIsByonSelected] = useState<boolean>();
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const docuSignService = new DocuSignService();

    // functions
    const getProofUrl = async () : Promise<void> => {
        setIsLoading(true);
        if (invoice)
        {
            const response = await docuSignService.getProofUrl(invoice.docusignEnvelopeid, invoice?.accessToken) as string
            invoice.proofLink = response;
            console.log("Proof link: " + invoice.proofLink);
            await updateInvoice(invoice);
            await getInvoice(invoice.accessToken);
        }
        setIsLoading(false);
    }

    // Event handler for "Continue to Proof" button
    const handleContinueToProof = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setIsLoading(true);
        setErrorMessage("");

        try {
            if (invoice && !invoice.proofLink) {
                await getProofUrl();
                if (invoice.proofLink) {
                    window.open(invoice.proofLink, "_self", "noreferrer");
                } else {
                    setErrorMessage("Failed to retrieve the proof link. Please try again.");
                }
            } else if (invoice?.proofLink) {
                window.open(invoice.proofLink, "_self", "noreferrer");
            } else {
                setErrorMessage("Invoice or proof link is missing. Please try again or contact support.");
            }
        } catch (error) {
            console.error("Error in handleContinueToProof:", error);
            setErrorMessage("An error occurred while processing your request. Please try again or contact BRIX.");
        } finally {
            setIsLoading(false);
        }
    }

    const handleBringYourOwnNotarySelected = async (event: FormEvent<HTMLFormElement>) : Promise<void> => {
        event.preventDefault();
        setIsLoading(true);
        setErrorMessage("");
        setIsByonSelected(true);
        setIsLoading(false);
    }

    const handleBringYourOwnNotarySubmitted = async (event: FormEvent<HTMLFormElement>) : Promise<void> => {
        event.preventDefault();
        setIsLoading(true);
        setErrorMessage("");
        try {
        
            if(selectedImage !== null && invoice){
                const formData = new FormData();
                formData.append('FormFile', selectedImage);
                
                let fileExtension = "";
                switch(selectedImage.type)
                {
                    case "application/pdf":
                        fileExtension = ".pdf";
                        break;
                    case "image/jpeg":
                        fileExtension = ".jpg";
                        break;
                    case "image/png":
                        fileExtension = ".png";
                        break;
                    default:
                        setErrorMessage("Error: please select a PDF, JPG, or PNG file to upload.");
                        setSelectedImage(null);
                        setIsLoading(false);
                        return;
                }

                if(fileExtension.length > 0){

                    // lien waiver upload - setup file name like "9e662f93-8389-4d5d-a0c3-c43883347ded-lien-waiver.pdf"
                    const uploadString = `${invoice.accessToken}-lien-waiver${fileExtension}`;

                    // lien waiver upload - upload to the FileController /upload endpoint, and to the /uploads Azure Blob Container
                    await httpService.post(`api/files/upload/uploads/${uploadString}`, formData);

                    // print for debugging purposes
                    console.log("Lien Waiver Object Id: " + uploadString);
                    
                    // save the upload string to the invoice for later reference/download of the lien waiver
                    invoice.lienWaiverObjectId = uploadString;
                    invoice.subStatus = "SUBNOTARIZED";
                    await updateInvoice(invoice);
                    await getInvoice(invoice.accessToken);
                }
            }
            else {
                alert('Please select an image first');
            }
        }
        catch
        {
            setErrorMessage("Error: Failed to upload. Please try again or contact BRIX.");
            console.log(errorMessage);
        }
        setIsLoading(false);
    }

    const handleImageSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedImage(event.target.files!==null ? event.target.files[0]: null );
     };

    if(isLoading)
    {
        return (
            <BrixProgressBar message="Loading..." />
        );
    }

   return (
    <div className={classes.root} data-testid="notarize-documents-container">
            <div>
                <BrixHeader title="Document Signing Complete!" subtitle="Your Lien Waiver is ready to be notarized." />
                <BrixBreadcrumb subStatus={invoice?.subStatus} />
                {/* <BrixField id="docusign-url-field" title="Docusign Link" value={receivableSaleData.documentId} disabled={false}></BrixField> */}
                {/* <BrixField id="proof-url-field" title="Proof Link" value={proofUrl} disabled={false}></BrixField> */}
                <BrixText text="You can use Proof.com to notarize an AIA Formatted Lien Waiver online right now for free, or you can upload a notarized lien waiver." />

                { errorMessage && 
                    <div className={classes.errorDiv}>
                        {errorMessage}
                    </div>
                }

                <div>

                    {    
                        isByonSelected && <>

                            <form onSubmit={handleBringYourOwnNotarySubmitted}>
                                <p>Upload a signed, notarized lien waiver (PDF or image) to continue:</p>
                                <div className={classes.fileUploadDiv}>
                                <input
                                    className={classes.fileUploadInput}
                                    type="file" 
                                    name="image" 
                                    accept="application/pdf,image/*"
                                    onChange={handleImageSelection}
                                    required/>
                                </div>
                                <BrixButton id="proof-byon-submit-button" title="Submit" disabled={false} isSubmitButton={true} data-testid="submit-notarized-lien-waiver" />
                            </form>
                        </>
                    }

                    

                    { !isByonSelected && <>
                        <form onSubmit={handleBringYourOwnNotarySelected}>
                            <BrixButton id="proof-byon-button" title="Bring Your Own Notary" disabled={false} isSubmitButton={true} data-testid="bring-your-own-notary" />
                        </form>
                    </>}
                        
                    
                    <form onSubmit={handleContinueToProof}>
                        <BrixButton id="proof-continue-button" title="Use Proof.com Notary" disabled={false} isSubmitButton={true} data-testid="use-proof-notary" />
                    </form>
                    </div>
                </div>
    </div>
   );
}

export default NotarizeDocumentsPage;