import { Invoice } from '../types/Invoice';
import { HttpService } from './HttpService';

const httpService = new HttpService();

export class InvoiceService {
  
  async get(id: string): Promise<Invoice> {
      const invoice = await httpService.get(`api/invoices/accessToken/${id}`) as Invoice;
      return invoice;
  }

  async create(invoice: Invoice): Promise<void> {
      await httpService.post('api/invoices', invoice);
  }

  async update(invoice: Invoice): Promise<void> {
      await httpService.put(`api/invoices/${invoice.invoice_id}`, invoice);
  }

  async delete(id: number): Promise<void> {
      await httpService.get(`api/invoices/${id}`);
  }
}

export default InvoiceService;