import { BankAccount } from '../types/BankAccount';
import { HttpService } from './HttpService';

const httpService = new HttpService();

export class BankAccountService {
  
  async get(id: string): Promise<BankAccount> {
      const bankaccount = await httpService.get(`api/bankaccounts/${id}`) as BankAccount;
      return bankaccount;
  }

  async getAnalysis(id: string): Promise<BankAccount> {
    const bankaccount = await httpService.get(`api/bankaccounts/${id}`) as BankAccount;
    return bankaccount;
  }

  async create(bankaccount: BankAccount): Promise<BankAccount> {
      const createdBankAccount = await httpService.post('api/bankaccounts', bankaccount) as BankAccount;
      return createdBankAccount;
  }

  async update(bankAccount: BankAccount): Promise<void> {
    const { bankAccount_id, bankName, routingNumber, accountNumber, checkImageObjectId } = bankAccount;
    
    const updateData = {
      bankName,
      routingNumber,
      accountNumber,
      checkImageObjectId
    };

    await httpService.put(`api/bankaccounts/${bankAccount_id}`, updateData);
  }

  async delete(id: number): Promise<void> {
      await httpService.get(`api/bankaccounts/${id}`);
  }
}

export default BankAccountService;