import { FC, useState, FormEvent, ChangeEvent, useEffect  } from 'react';
import BrixHeader from '../components/BrixHeader';
import BrixField from '../components/BrixField';
import BrixButton from '../components/BrixButton';
import { useInvoiceContext } from '../contexts/InvoiceContext';
import { makeStyles } from '@fluentui/react-components';
import InvoiceService from '../services/InvoiceService';
import BrixProgressBar from '../components/BrixProgressBar';

const useStyles = makeStyles({
  root: {
    textAlign: "center",
    alignContent: "center",
    justifyContent: "center",
    width: "100%"
  },
  errorDiv: {
    marginTop: '10px',
    color: '#cb0043'
  },
});

const SelectOfferCodePage: FC = () => {
  
  // uses
  const classes = useStyles();

  // states
  const [inputOfferCode, setInputOfferCode ] = useState<string>("");
  const [outputMessage, setOutputMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>();
  const [areSpecialButtonsVisible, setAreSpecialButtonsVisible] = useState<boolean>(false);

  // contexts
  const { getInvoice } = useInvoiceContext();

  // services
  let invoiceService = new InvoiceService();

  useEffect(() => {
    const listener = function (e: KeyboardEvent) {
      if (e.key === "`") {
        setAreSpecialButtonsVisible(!areSpecialButtonsVisible);
      }
    };

    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [areSpecialButtonsVisible]);

  // Event handler for when the value of the offer code input box changes
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) : void => {
    setInputOfferCode(event.target.value);
  }

  // Event handler for the manual offer code form submission
  const handleOfferCodeSubmit = async (event: FormEvent<HTMLFormElement>) : Promise<void> => {
    event.preventDefault(); // Prevent page refresh
    if(inputOfferCode.length == "e0ee01bd-47dd-4cf7-8604-220631043574".length)
    {
      setIsLoading(true);
      setInputOfferCode(inputOfferCode);
      await getInvoice(inputOfferCode);
      setIsLoading(false);
    }
  }

  // Event handler for filling in the demo offer code
  const handleUseDemoOfferCodeButtonClicked = (event: FormEvent<HTMLFormElement>) : void => {
    event.preventDefault();
    const demoOfferCode = "e0ee01bd-47dd-4cf7-8604-220631043574";
    setInputOfferCode(demoOfferCode);
  }

  // Event handler for resetting the demo offer code
  const handleResetDemoOfferCodeButtonClicked = async (event: FormEvent<HTMLFormElement>) : Promise<void> => {
    event.preventDefault();
    setIsLoading(true);
    
    try
    {
      
      const demoOfferCode = "e0ee01bd-47dd-4cf7-8604-220631043574";
      const demoOffer = await invoiceService.get(demoOfferCode);

      if(demoOffer){
        demoOffer.subStatus = "";
        demoOffer.docuSignLink = "";
        demoOffer.docusignEnvelopeid = "";
        demoOffer.saleAmount = 0;
        demoOffer.proofLink = "";
        demoOffer.lienWaiverObjectId = "";
        await invoiceService.update(demoOffer);
        setOutputMessage("Demo was reset.");
      }
      else {
        setOutputMessage("Demo was not able to be reset.");
      }
    
    }
    catch 
    {
      setOutputMessage("Error retrieving invoice data. Please try again.");
    }

    setIsLoading(false);
  }

  // Event handler for resetting the demo offer code
  const handleResetOfferCodeButtonClicked = async (event: FormEvent<HTMLFormElement>) : Promise<void> => {
    event.preventDefault();
    setIsLoading(true);
    
    try
    {
      const resettingOffer = await invoiceService.get(inputOfferCode);

      if(resettingOffer){
        resettingOffer.subStatus = "";
        resettingOffer.docuSignLink = "";
        resettingOffer.docusignEnvelopeid = "";
        resettingOffer.saleAmount = 0;
        resettingOffer.proofLink = "";
        resettingOffer.lienWaiverObjectId = "";
        await invoiceService.update(resettingOffer);
        setOutputMessage(`Offer code ${inputOfferCode} was reset.`);
      }
      else {
        setOutputMessage(`Offer code ${inputOfferCode} was not able to be reset.`);
      }
    
    }
    catch 
    {
      setOutputMessage("Error retrieving invoice data. Please try again.");
    }

    setIsLoading(false);
  }

  // Event handler for clearing the offer code manual entry form
  const handleOfferCodeClear = (event: FormEvent<HTMLFormElement>) : void => {
    event.preventDefault();
    setInputOfferCode("");
  }

  if(isLoading){
    return (
        <BrixProgressBar message="Loading..." />
    );
  }

  return (

    <div data-testid="select-offer-code-container">
      <BrixHeader title="Welcome to Brix!" subtitle="Please click the link we emailed you, or enter your offer code below:" />

      
      <div className={classes.errorDiv}>
        {outputMessage}
      </div>

      <form onSubmit={handleOfferCodeSubmit}>
        <BrixField id="offer-code-input-field" title="Offer Code" disabled={false} value={inputOfferCode} onChange={handleInputChange} data-testid="offer-code-input"/>
        <BrixButton id="offer-code-button-submit" title="Submit" disabled={false} isSubmitButton={true} data-testid="submit-offer-code" />
      </form>
      <form onSubmit={handleOfferCodeClear}>
        <BrixButton id="offer-code-clear-button" title="Clear" disabled={false} isSubmitButton={true} data-testid="clear-offer-code" />
      </form>
      {
        areSpecialButtonsVisible && (
          <>
            <form onSubmit={handleResetOfferCodeButtonClicked}>
              <BrixButton id="offer-code-reset-button" title="Reset This Offer Code" disabled={false} isSubmitButton={true} data-testid="reset-offer-code" />
            </form>
            <form onSubmit={handleUseDemoOfferCodeButtonClicked}>
              <BrixButton id="offer-code-use-demo-code-button" title="Use Demo" disabled={false} isSubmitButton={true} data-testid="use-demo-offer-code" />
            </form>
            <form onSubmit={handleResetDemoOfferCodeButtonClicked}>
              <BrixButton id="offer-code-reset-demo-button" title="Reset Demo" disabled={false} isSubmitButton={true} data-testid="reset-demo-offer-code" />
            </form>
          </>
        )
      }
    </div>
  );
};

export default SelectOfferCodePage;