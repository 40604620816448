import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/react'; // Import Sentry

const apiBaseUrl = import.meta.env.VITE_API_URL;

export class HttpService {
  private baseUrl: string;

  constructor(baseUrl?: string) {
    this.baseUrl = baseUrl || apiBaseUrl;
  }

  private handleError(error: any, url: string, method: string, data?: any): Error {
    console.error(`Error in HttpService ${method} ${url}:`, error);

    let enhancedError: Error;
    if (axios.isAxiosError(error)) {
      const status = error.response?.status || 'Unknown';
      const message = error.message;
      const headers = error.config?.headers;

      enhancedError = new Error(
        `${status} Error: ${method} request failed for URL ${this.baseUrl}/${url} with message: ${message}`
      );

      Sentry.captureException(enhancedError, {
        extra: {
          url: `${this.baseUrl}/${url}`,
          method,
          data,
          status,
          headers,
        },
      });
    } else {
      enhancedError = new Error(`Unknown Error: ${method} request failed for URL ${this.baseUrl}/${url} with message: ${error.message}`);
      Sentry.captureException(enhancedError);
    }

    // Include the original error stack trace for better debugging
    console.error(error.stack);

    // Return the enhanced error to be thrown in the catch block
    return enhancedError;
  }

  async get(url: string) {
    console.log("HttpService GET " + `${this.baseUrl}/${url}`);

    try {
      const response = await axios.get(`${this.baseUrl}/${url}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw this.handleError(error as AxiosError, url, 'GET');
    }
  }

  async post(url: string, data: any) {
    console.log("HttpService POST " + `${this.baseUrl}/${url}`);
    console.log(data);

    try {
      const response = await axios.post(`${this.baseUrl}/${url}`, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw this.handleError(error as AxiosError, url, 'POST', data);
    }
  }

  async put(url: string, data: any) {
    console.log("HttpService PUT " + `${this.baseUrl}/${url}`);
    console.log(data);

    try {
      const response = await axios.put(`${this.baseUrl}/${url}`, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw this.handleError(error as AxiosError, url, 'PUT', data);
    }
  }

  async delete(url: string) {
    console.log("HttpService DELETE " + `${this.baseUrl}/${url}`);

    try {
      const response = await axios.delete(`${this.baseUrl}/${url}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw this.handleError(error as AxiosError, url, 'DELETE');
    }
  }
}