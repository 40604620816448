// Usage:
// import ViewOfferPage from './components/ViewOfferPage'

import { FC } from 'react';
import { 
    makeStyles
} from '@fluentui/react-components';
import BrixHeader from '../components/BrixHeader';
import BrixBreadcrumb from '../components/BrixBreadcrumb';

const useStyles = makeStyles({
   root: {
       //color: 'blue'
   },
   sliderField: {
    textAlign: "left",
    paddingBottom: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
});

const ExpiredOfferPage: FC = () => {

    // usages
    const classes = useStyles();

    return (
        <div className={classes.root} data-testid="expired-offer-container">
            <BrixHeader title="Offer Expired" subtitle="Please reach out to your General Contractor for a new offer."></BrixHeader>
            <BrixBreadcrumb subStatus={"SUBFINISHED"} />
        </div>
    );
}

export default ExpiredOfferPage;