import { createContext, useContext } from 'react';
import { Invoice } from '../types/Invoice';

interface InvoiceContextProps {
  invoice: Invoice | undefined;
  bankAccountId: string | undefined;
  setInvoice: (invoice: Invoice | undefined) => void;
  setBankAccountId: (id: string | undefined) => void;
  getInvoice: (id: string) => Promise<void>;
  createInvoice: (invoice: Invoice) => Promise<void>;
  updateInvoice: (invoice: Invoice) => Promise<void>;
  deleteInvoice: (id: number) => Promise<void>;
}

const InvoiceContext = createContext<InvoiceContextProps | undefined>(undefined);

export function useInvoiceContext() {
  const context = useContext(InvoiceContext);
  if (context === undefined) {
    throw new Error('useInvoiceContext must be used within an InvoiceProvider');
  }
  return context;
}

export default InvoiceContext;
