// Usage:
// import BrixHeader from './components/BrixHeader'

import { FC } from 'react';
import { 
    FieldProps,
    makeStyles,
    Title1,
    Subtitle1
} from '@fluentui/react-components';

const useStyles = makeStyles({
   root: {
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
       justifyContent: "center",
       paddingBottom: "20px"
   }
});

interface BrixHeaderProps extends FieldProps {
    title: string;
    subtitle: string;
    subtitle2?: string;
}

const BrixHeader: FC<BrixHeaderProps> = ({ title, subtitle, subtitle2 }) => {

   const classes = useStyles();

   return (
    <div className={classes.root}>
        <div>
            <Title1>{title}</Title1>
        </div>
        <div>
            <Subtitle1>{subtitle}</Subtitle1>
            {subtitle2 && <div><Subtitle1>{subtitle2}</Subtitle1></div>}
        </div>
    </div>
   );
}

export default BrixHeader;