import React, { useEffect, useState } from 'react';
import SelectOfferCodePage from './pages/SelectOfferCodePage';
import ViewOfferPage from './pages/ViewOfferPage';
import SignDocumentsPage from './pages/SignDocumentsPage';
import { makeStyles } from "@fluentui/react-components";
import BrixImage from './components/BrixImage';
import logoImage from './assets/brix-logo-color.png';
import NotarizeDocumentsPage from './pages/NotarizeDocumentsPage';
import UploadCheckPage from './pages/UploadCheckPage';
import ViewFinishSummaryPage from './pages/ViewFinishSummaryPage';
import DeclineOfferPage from './pages/DeclineOfferPage';
import CompanyInfoPage from './pages/CompanyInfoPage';
import ExpiredOfferPage from './pages/ExpiredOfferPage';
import { isValidGuid } from './utils/GuidUtils';
import { useInvoiceContext } from './contexts/InvoiceContext';
import { hasDatePassed } from './utils/DateUtils';
import BrixProgressBar from './components/BrixProgressBar';
//import { useInvoiceSubmission } from './contexts/InvoiceSubmissionContext';
//import { useReceivableSale } from './contexts/ReceivableSaleContext';

const useStyles = makeStyles({
  root: {
    textAlign: "center",
    alignContent: "center",
    justifyContent: "center",
    width: "100%"
  }
});

const App: React.FC = () => {

  // constant declarations
  const classes = useStyles();
  
  // Extract the path from the location 
  const path = window.location.pathname;
  
  const urlOfferCode = path.split('/')[1];
  const urlCommand = path.split('/')[2];

  const { invoice, getInvoice, updateInvoice } = useInvoiceContext();

  const [isLoading, setIsLoading] = useState<boolean>();

  const getInvoiceAsync = async (offerCode: string) => {
    
    setIsLoading(true);
    
    await getInvoice(offerCode);

    if(invoice)
    {
      if(invoice.subStatus.length == 0)
      {
        invoice.subStatus = "SUBCREATED";
        await updateInvoice(invoice);
        await getInvoice(invoice.accessToken);
      }
    }

    setIsLoading(false);
  }

  useEffect(() => {

    
      if(urlOfferCode && isValidGuid(urlOfferCode) && !invoice)
      {
        console.log("getInvoiceSubmission...");
        getInvoiceAsync(urlOfferCode);
      }

      if(invoice){
          // if the user just returned from the proof page to the proof redirect url we set up,
          // then advance the subStatus to the "SUBNOTARIZED" stage, which will display the check upload screen and save the stage
          console.log("URLCOMMAND: " + urlCommand);
          if(invoice.subStatus == "SUBDOCUSIGNED" && urlCommand == "check-upload")
          {
            console.log("COMPLETING");
            invoice.subStatus = "SUBNOTARIZED";
            updateInvoice(invoice);
            getInvoice(invoice.accessToken);
          }

        }


  }, [urlOfferCode, invoice]);

  
  if(isLoading){
    return (
      <div className={classes.root}>
        <BrixImage url={logoImage} />
        <BrixProgressBar message="Loading..." />
      </div>
    );
  }

  if(!invoice && !urlOfferCode){
    window.location.href = 'https://www.brixfunding.com';
  }

  return (
    <div className={classes.root} data-testid="app-root">

      <BrixImage url={logoImage} data-testid="brix-logo" />

      {
        // if the url ends in /demo, go to select offer code page
        (!invoice && urlOfferCode == "demo") &&
        <SelectOfferCodePage data-testid="select-offer-code-page" />
      }

      {
        (invoice?.subStatus == "SUBEXPIRED" || hasDatePassed(invoice?.expirationDate.toString())) && 
        <>
          <ExpiredOfferPage data-testid="expired-offer-page" />
        </>
      }
      
      { 
        !hasDatePassed(invoice?.expirationDate.toString()) &&
        <>
            {
              (invoice?.subStatus == "SUBCREATED" || invoice?.subStatus == "" || invoice?.subStatus == null) && 
              <ViewOfferPage data-testid="view-offer-page" />
            }

            {
              (invoice?.subStatus == "SUBDECLINED") && 
              <>
                <DeclineOfferPage data-testid="decline-offer-page" />
              </>
            }

            {
              invoice?.subStatus == "SUBACCEPTED" && 
              <>
                <CompanyInfoPage data-testid="company-info-page" />
              </>
            }

            {
              invoice?.subStatus == "SUBCOMPANYINFOPROVIDED" && 
              <>
                <SignDocumentsPage data-testid="sign-documents-page" />
              </>
            }

            {
              invoice?.subStatus == "SUBDOCUSIGNED" && 
              <>
                <NotarizeDocumentsPage data-testid="notarize-documents-page" />
              </>
            }
            
            {
              invoice?.subStatus == "SUBNOTARIZED" && 
              <>
                <UploadCheckPage data-testid="upload-check-page" />
              </>
            }

            {
              invoice?.subStatus == "SUBACCOUNTINFOPROVIDED" && 
              <>
                <ViewFinishSummaryPage data-testid="view-finish-summary-page" />
              </>
            }
            
            {
              invoice?.subStatus == "SUBFINISHED" && 
              <>
                <ViewFinishSummaryPage data-testid="view-finish-summary-page" />
              </>
            }
        </>
      }

      
      
      {/* <Routes>
        <Route path="/" element={<SelectOfferCodePage />} />
        <Route path="/:urlOfferCode" element={<ViewOfferPage />} />
        <Route path="/:urlOfferCode/companyInfo" element={<CompanyInfoPage />} />
        <Route path="/decline" element={<DeclineOfferPage />} />
        <Route path="/expired" element={<ExpiredOfferPage />} />
        <Route path="/:urlOfferCode/signDocuments" element={<SignDocumentsPage />} />
        <Route path="/:urlOfferCode/getNotarized" element={<NotarizeDocumentsPage />} />
        <Route path="/:urlOfferCode/uploadCheck" element={<UploadCheckPage />} />
        <Route path="/finish" element={<ViewFinishSummaryPage />} />
      </Routes> */}
    </div>
  );
};

export default App;