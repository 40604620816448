/**
 * Utility Function - hasDatePassed
 * @param expirationDate - A date string in the format: YYYY-MM-DD
 * @returns A boolean value.
True if date has passed, false if date has not passed.
 */
export function hasDatePassed(expirationDate: string | undefined): boolean {

    if(expirationDate == undefined){
        return false;
    }

    // Parse the expirationDate with ISO format.
    const expiry = new Date(expirationDate);
  
    // Get current date
    const now = new Date();

    /* Comparison */
    // If the expiry date is earlier than the current date, it has passed (return true).
    // Otherwise, it has not passed (return false).
    if (expiry < now) {
        return true; // Date has passed
    } else {
        return false; // Date has not passed
    }
}