import { ReceivableSale } from '../types/ReceivableSale';
import { HttpService } from './HttpService';

const httpService = new HttpService();

export class ReceivableSaleService {
  async get(accessToken: string): Promise<ReceivableSale> {
      const invoices = await httpService.get(`api/receivablesales/${accessToken}`) as ReceivableSale[];
      return invoices[0];
  }

  async create(receivableSale: ReceivableSale): Promise<void> {
      await httpService.post('api/receivablesales', receivableSale);
  }

  async update(receivableSale: ReceivableSale): Promise<void> {
      await httpService.put('api/receivablesales', receivableSale);
  }

  async delete(id: number): Promise<void> {
      await httpService.get(`api/receivablesales/${id}`);
  }
}

export default ReceivableSaleService;