// Usage:
// import BrixDropdown from './components/BrixDropdown'

import { FC } from 'react';
import { 
    FieldProps,
    makeStyles,
    Dropdown,
    Option,
    Field
} from '@fluentui/react-components';
import {
    
  } from "@fluentui/react-components";

const useStyles = makeStyles({
   root: {
    paddingBottom: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    textAlign: "left"
   }
});

interface BrixDropdownProps extends FieldProps {
    id: string;
    options: string[];
    label: string;
    defaultValue?: string; 
    onOptionChange?: (option: string) => void;
    disabled?: boolean;
    'data-testid'?: string;
}

const BrixDropdown: FC<BrixDropdownProps> = ({ 
    id, 
    options, 
    label, 
    defaultValue, 
    onOptionChange, 
    disabled,
    'data-testid': dataTestId
}) => {

   const classes = useStyles();

    console.log(id);

    return (
        <div className={classes.root}>
            <Field
                id={id}
                label={label}
                size="large"
            >
                <Dropdown
                    aria-labelledby={id}
                    // placeholder={label}
                    appearance="underline" 
                    size="large"
                    defaultValue={defaultValue}
                    onActiveOptionChange={(event, data) => {
                        if(event && data?.nextOption?.text) {
                            onOptionChange?.(data?.nextOption?.text);
                        }
                    }}
                    disabled={disabled}
                    data-testid={dataTestId}
                >
                    {options.map((option) => (
                        <Option key={option}>
                            {option}
                        </Option>
                    ))}
                </Dropdown>
            </Field>
        </div>
       );
}

export default BrixDropdown;