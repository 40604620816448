import { SubProfile } from '../types/SubProfile';
import { HttpService } from './HttpService';

const httpService = new HttpService();

export class SubProfileService {
  
  async get(id: number): Promise<SubProfile> {
      const subprofile = await httpService.get(`api/subprofiles/${id}`) as SubProfile;
      return subprofile;
  }

  async create(subprofile: SubProfile): Promise<SubProfile> {
    const createdSubProfile = await httpService.post('api/subprofiles', subprofile) as SubProfile;
    return createdSubProfile;
  }

  async update(subprofile: SubProfile): Promise<void> {
      await httpService.put(`api/subprofiles/${subprofile.subProfile_id}`, subprofile);
  }

  async delete(id: number): Promise<void> {
      await httpService.get(`api/subprofiles/${id}`);
  }
}

export default SubProfileService;