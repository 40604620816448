import { Sub } from '../types/Sub';
import { SubContractorResponse } from '../types/data-contracts.ts';
import { HttpService } from './HttpService.tsx';

const httpService = new HttpService();

export class SubService {
  
  async get(id: number): Promise<Sub> {
      const sub = await httpService.get(`api/subcontractors/${id}`) as Sub;
      return sub;
  }

  async create(sub: Sub): Promise<Sub> {
      const returnedSub = await httpService.post('api/subcontractors', sub);
      return returnedSub;
  }

  async update(sub: Sub): Promise<void> {
      await httpService.put(`api/subcontractors/${sub.subContractor_id}`, sub);
  }

  async delete(id: number): Promise<void> {
      await httpService.get(`api/subcontractors/${id}`);
  }
  async getByEIN(ein: string): Promise<SubContractorResponse | null> {
    try {
      const response = await httpService.get(`api/subcontractors/ein/${ein}`);
      if (response === null || response === undefined) {
        // This handles the 204 No Content response
        return null;
      }
      return response as SubContractorResponse;
    } catch (error) {
      console.error('Error fetching subcontractor by EIN:', error);
      return null;
    }
  }
}

export default SubService;